.info-box-text-container {
  /* margin-top: 100px; */
  background-color: white;
  width: 100%;
  justify-content: center;
  max-height: 400;
}

.info-box-left {
  padding: 20px;
  flex-direction: column;
  flex: 1;
  text-align: left;
}

.info-box-right {
  padding: 20px;
  flex-direction: column;
  flex: 1;
  text-align: left;
}

.info-box-list {
  padding-inline-start: 0;
}

.info-box-list-mobile {
  padding-inline-start: 0;
  margin-top: -5px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.info-box-left-mobile {
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  flex: 1;
  text-align: left;
}

.info-image {
  object-fit: contain;
  margin: auto;
}

.info-image-mobile {
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

.info-box-text-container-mobile {
  width: 100%;
  /* flex: 1;
  display: flex; */
  max-height: 500px;
  overflow-y: scroll;
  margin: auto;
  align-items: safe center;
}

.info-box-mobile {
  justify-self: flex-end;
  bottom: 0;
  flex-direction: column;
  background-color: white;
  z-index: 2000;
  flex-grow: 1;
}

.info-box {
  flex-grow: 1;
  justify-self: flex-end;
  bottom: 0;
  flex-direction: column;
  background-color: white;
}

.list-item-row {
  margin-top: 5px;
}

.info-box-list-item {
  list-style: none;
  margin-left: 5px;
}

.list-item-icon {
  color: blue;
}

.info-text {
  color: black;
  font-size: 1.3em;
  line-height: 1.5em;
}

.info-title {
  font-size: 1.3em;
  font-weight: bold;
}
