.timeline-container {
  height: 100px;
  width: 100%;
  align-items: center;
  flex-direction: row;
  background-color: #282c34;
  z-index: 1000;
}

.timeline-date-container {
  flex-direction: column;
  justify-content: center;
}

.timeline-bar {
  align-self: center;
  background-color: lightgray;
  height: 10px;
  opacity: 0.25;
}

.timeline-bar.left {
  width: 50px;
  margin-right: 15px;
}

.timeline-bar.inner {
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
}

.timeline-bar.right {
  width: 50px;
  margin-left: 15px;
}

.timeline-date {
  color: white;
}

.timeline-date-container.hover > .timeline-date {
  color: rgba(255, 255, 255, 1);
}

.timeline-date-container.no-hover > .timeline-date {
  color: rgba(255, 255, 255, 0.6);
}

.month .year {
  font-size: 1.5em;
}

.day {
  font-size: 2em;
  font-weight: bold;
}

.timeline-controls-container {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.left-button {
  border-radius: 5px;
  border-width: 1px;
  border-color: black;
  background-color: lightgray;
  width: 25px;
  height: 25px;
}

.right-button {
  border-radius: 5px;
  border-width: 1px;
  border-color: black;
  background-color: lightgray;
  width: 25px;
  height: 25px;
  margin-left: 5px;
}

.timeline-controls-container.hover > .left-button,
.timeline-controls-container.hover > .right-button {
  background-color: white;
}

.timeline-tracker {
  transform: rotate(90deg);
  position: fixed;
}
