.App {
  flex: 1;
  flex-direction: column;
  text-align: center;
  background-color: #282c34;
}

p {
  color: white;
  margin: 0;
  font-weight: lighter;
}

html body {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
}

div {
  display: flex;
}

#root {
  display: flex;
  flex: 1;
  height: 100%;
}
