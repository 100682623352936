.display-side-center {
  z-index: 1001;
}

.tagline {
  color: white;
  align-self: center;
  font-weight: bold;
  padding-top: 1em;
}

.byline {
  color: white;
}

.bylineRow {
  flex-direction: row;
  max-width: 70%;
  padding-top: 1em;
}

.header-card {
  flex-direction: column;
  position: fixed;
  align-items: center;
}

.header-card-content {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
